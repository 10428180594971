/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../../templates/room/songSearch.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('mySongSearch', ($rootScope, $timeout) => {
        return {
            restrict: 'E',
            template,
            scope: {
                inRoomList: '=',
                inWikiSongPage: '=',
                relatedSong: '='
            },
            controller: function($scope, $http, helper, $state, $q,
                                 roomService, beatsService, storage, userService,
                                 analyticsService, songService){
                var searchCounter = 0;
                var suggestionSearchCounter = 0;
                var minQueryLength = 2;
                var searchLocked;
                var searchRequested;
                var searchLockTimer = null;
                var lastSearchCanceler;
                var prevSearch;
                let _searchResultsScrollPosition = 0;

                const searchResultsSelector = '#youtube-search-results-holder';
                const inputButtonSelector = '#add-song-holder .input-holder button';

                $scope.errors = {};

                const setSearchResultsScroller = (position = 0) => {
                    const elem = $(searchResultsSelector);

                    // Fix
                    _searchResultsScrollPosition = position;
                    elem.scrollTop(position);
                    elem.perfectScrollbar('update');
                };

                $scope.beatsService = beatsService;
                $scope.userService = userService;
                $scope.digDeeperPrice = beatsService.getFeaturePrice(beatsService.keys.digDeeper);

                $scope.isPlaylistHasVariety = roomService.isPlaylistHasVariety();
                $scope.$on('playlist:updated:client', () => {
                    $scope.isPlaylistHasVariety = roomService.isPlaylistHasVariety();
                });

                $scope.searchOnYoutube = async (options = {}) => {
                    try {
                        options.searchType = options.searchType || 'int';

                        $scope.loadingYoutubeResults = false;
                        $scope.addSongLoading = false;

                        if (!$scope.querySearch || $scope.querySearch.length < minQueryLength) return;

                        if ($scope.querySearch.indexOf('youtu.be') === -1 && $scope.querySearch.indexOf('youtube.com/') === -1) {
                            $scope.aSearchByURL = false;
                        } else {
                            $scope.aSearchByURL = true;
                        }

                        $scope.youtubeSuggestionsSearchResults = [];
                        $scope.showYoutueNoResultsMessage = false;

                        const params = {
                            q: $scope.querySearch,
                            maxResults: 35
                        };

                        const currIndex = ++searchCounter;
                        $scope.loadingError = null;

                        const cachedResults = storage.getItem('searchQuery_' + params.q); //cached results of immediate yt requests (happen in song router)
                        if (cachedResults !== null) {
                            $scope.youtubeSearchResults = cachedResults;
                            $scope.showDigDeeper = false;

                            analyticsService.trackActionEvent({
                                eventAction: 'Cached',
                                eventLabel: $scope.querySearch + '__' + userService.getUserName()
                            });

                            return;
                        }

                        $scope.loadingYoutubeResults = true;

                        const { index, res } = await songService.search(options.searchType, currIndex, params);
                        $scope.loadingYoutubeResults = false;

                        if (!res) return;

                        const songs = (res.data || []).map(item => {
                            return {
                                ...item,
                                wikiSong: songService.fromYoutubeSong(item)
                            };
                        });

                        $scope.isYoutubeDisabled = $rootScope.isYoutubeDisabled = res.isYoutubeDisabled;

                        if (index === searchCounter) {
                            if (res.source === 'yt' && $scope.youtubeSearchResults) {
                                $scope.youtubeSearchResults = $scope.youtubeSearchResults.concat(songs);
                                //if( res.isImmediate ){
                                storage.setItem('searchQuery_' + params.q, $scope.youtubeSearchResults, helper.minutesToMs(15));
                                //}
                            } else {
                                $scope.youtubeSearchResults = songs;
                                $timeout(() => {
                                    setSearchResultsScroller(0);
                                });
                            }

                            if (!$scope.youtubeSearchResults.length && (!songs || !songs.length)) {
                                $scope.showYoutueNoResultsMessage = true;
                            }
                        }

                        $scope.showDigDeeper = res.source !== 'yt';

                        if (!$scope.youtubeSearchResults?.length) {
                            if ($scope.isYoutubeDisabled && res.source === 'yt') {
                                //do not shoot event when trying to call youtube with no quota
                            } else {
                                analyticsService.trackActionEvent({
                                    eventAction: `${res.source}__noResutls'`,
                                    eventLabel: `${$scope.querySearch}__${userService.getUserName()}`
                                });
                            }
                        } else {
                            if (res.isImmediate) {
                                analyticsService.trackActionEvent({
                                    eventAction: 'immediate_yt',
                                    eventLabel: $scope.querySearch + '__' + userService.getUserName()
                                });
                            } else {
                                analyticsService.trackActionEvent({
                                    eventAction: res.source,
                                    eventLabel: $scope.querySearch + '__' + userService.getUserName()
                                });
                            }
                        }
                    } catch (e) {
                        helper.error(e);
                        $scope.loadingYoutubeResults = false;
                        $scope.loadingError = e;

                        //sometimes youtube return 400, need to try again, one time
                        if (!options.retry) {
                            try {
                                await $scope.searchOnYoutube({searchType: 'yt', retry: true});
                            } catch (e) {
                                helper.error(e);
                            }
                        }
                    }
                }

                $scope.quickSearch = function(query) {
                    if($scope.querySearch !== query){

                        analyticsService.trackUIEvent({
                            eventAction: 'QuickSearchClicked',
                            eventLabel: query
                        });

                        $scope.querySearch = query;
                        $scope.onSuggestionResultClicked(query);
                    }
                };

                $scope.onSongSearchKeyup = function($event = {}){

                    // Immediate action
                    if($event.keyCode === 13 || $event.keyCode === 40 || $event.keyCode === 38) {
                        searchRequested = false;
                        return $scope.getYoutubeSuggestions($event);
                    }

                    if(prevSearch === $scope.querySearch) return;
                    prevSearch = $scope.querySearch;

                    // Block hitting the server
                    if(!searchLocked){

                        if(searchLockTimer){
                            $timeout.cancel(searchLockTimer);
                            searchLockTimer = null;
                        }

                        $scope.getYoutubeSuggestions($event);

                        searchRequested = false;
                        searchLocked = true;
                        searchLockTimer = $timeout(function(){
                            searchLocked = false;

                            if(searchRequested){
                                $scope.getYoutubeSuggestions($event);
                                searchRequested = false;
                            }
                        }, 1500);
                    }else{
                        searchRequested = true;
                    }
                };

                $scope.getYoutubeSuggestions = function($event){

                    //helper.debug('Key code: ' + $event.keyCode);

                    if(!$scope.querySearch || $scope.querySearch.length === 0) {
                        $scope.resetYoutubeSearch();
                        return;
                    }

                    if($scope.querySearch.length < minQueryLength) return;

                    if(lastSearchCanceler){
                        helper.debug('Cancelling prev search');
                        lastSearchCanceler.resolve('cancelled');
                        lastSearchCanceler = null;
                    }

                    var keyCode = $event && $event.keyCode;

                    // In case user click Enter key - start youtube search immediately
                    if(keyCode === 13) {
                        var selectedResult;
                        if($scope.youtubeSuggestionsSearchResults && $scope.youtubeSuggestionsSearchResults.length){
                            selectedResult = $('#youtube-suggestions-results').find('> div[data-curr-elem]');

                            if(selectedResult.length){
                                $scope.querySearch = $.trim(selectedResult.text());
                            }

                            // If the user entered enter but not selected one of the suggestion results, make youtube search with his input
                            return $scope.searchOnYoutube();

                        }else if($scope.youtubeSearchResults && $scope.youtubeSearchResults.length){
                            selectedResult = $('#youtube-search-results-holder').find('> div[data-curr-elem]');
                            if(selectedResult.length){
                                $scope.onSongResultClicked({id: selectedResult.attr('data-yt-id')}, false);
                                return;
                            }
                        }else{
                            return $scope.searchOnYoutube();
                        }
                    }

                    // In case of user clicked arrow up/down
                    if(keyCode === 40 || keyCode === 38){

                        var options;

                        if($scope.youtubeSuggestionsSearchResults.length){
                            options = {
                                direction: keyCode === 38 ? 'prev' : 'next',
                                parent: '#youtube-suggestions-results',
                                child: '>div.youtube-suggestion-search-result'
                            };
                        }else{
                            options = {
                                direction: keyCode === 38 ? 'prev' : 'next',
                                parent: '#youtube-search-results-holder',
                                child: '>div.youtube-search-result'
                            };
                        }

                        var currElem = helper.scrollByArrows(options);

                        if(currElem){
                            $scope.querySearch = (currElem.find('.song-title').length ? currElem.find('.song-title > a').text() : currElem.text()).trim();
                        }

                        return;
                    }

                    // Reset youtube results
                    $scope.resetYouYoutubeResults();

                    // Start suggestions search
                    var url = '//suggestqueries.google.com/complete/search?client=youtube&hl=en&ds=yt&callback=JSON_CALLBACK&q='+encodeURIComponent($scope.querySearch);

                    lastSearchCanceler = $q.defer();

                    $scope.loadingYoutubeResults = true;
                    $scope.loadingError = null;
                    $http.jsonp(url, {
                        //cache: true,
                        transformRequest: function(data, headersGetter){
                            // Since it's external request, remove our custom header
                            delete headersGetter()['X-Angular-Ajax'];
                        },
                        timeout: lastSearchCanceler.promise
                    }).success((function(index, querySearch){
                        return function(data){

                            $scope.loadingYoutubeResults = false;

                            helper.debug('Prev search success for: '+querySearch+', index: '+index);

                            lastSearchCanceler = null;

                            // Avoid old responses
                            if(index === suggestionSearchCounter){
                                if(data && data[1] instanceof Array && data[1].length){
                                    $scope.youtubeSuggestionsSearchResults =  data[1];
                                }else{
                                    $scope.searchOnYoutube().catch(e => helper.error(e));
                                }
                            }
                        }
                    }(suggestionSearchCounter, $scope.querySearch))).error(function(response){

                        $scope.loadingYoutubeResults = false;

                        helper.debug('Prev search cancelled!');
                        lastSearchCanceler = null;
                        if(response){
                            helper.log('Youtube search failed: '+response);
                        }

                        $scope.loadingError = response;
                    });
                };

                $scope.onSuggestionResultClicked = function(query){
                    if(!query || !query.length) return;
                    $scope.querySearch = query;
                    $scope.searchOnYoutube().catch(e => helper.error(e));
                    $scope.youtubeSuggestionsSearchResults = [];
                };

                $scope.backToUserSearch = function($event){
                    $event.preventDefault();

                    if(!prevSearch) return;
                    $scope.querySearch = prevSearch;
                    $scope.getYoutubeSuggestions();
                };

                $rootScope.clearSearchInput = function(){
                    $timeout(function () {
                        $scope.querySearch = '';
                    });
                }

                $scope.onSearchFocus = function(){
                    $scope.showYoutueNoResultsMessage = false;
                };

                $scope.resetYoutubeSearch = function(){
                    $scope.youtubeSuggestionsSearchResults = [];
                    $scope.youtubeSearchResults = [];
                    $scope.querySearch = '';
                    $scope.showYoutueNoResultsMessage = false;
                    $scope.addSongLoading = false;
                    $scope.loadingYoutubeResults = false;
                    $scope.showDigDeeper = false;

                    $('#add-song-holder .search-box > input').focus();
                };

                $scope.resetYouYoutubeResults = function(){
                    $scope.youtubeSearchResults = [];
                    $scope.showYoutueNoResultsMessage = false;
                    $scope.showDigDeeper = false;
                };

                $scope.addingSongDone = function(id, data){
                    $scope.addSongLoading = false;

                    if(data?.message) {
                        $rootScope.showNotification(data.message);
                    }

                    $timeout(() => {
                        setSearchResultsScroller(_searchResultsScrollPosition);
                    });

                };

                $scope.onSongResultLinkClicked = ($event) => {
                    if ($scope.inWikiSongPage || $rootScope.isMobile) {
                        $event.preventDefault();
                    }
                };

                $scope.onSongResultClicked = async function (song) {

                    if ($scope.inWikiSongPage) {
                        await analyticsService.trackWikiEventP({
                            eventAction: 'songSearched'
                        });
                        return helper.redirectToWiki(songService.fromYoutubeSong(song));
                    }

                    if ($rootScope.isMobile) {
                        helper.gotoState('song-modal', {
                            id: song.id,
                            name: songService.getSongName(song)
                        });
                    }
                };

                $scope.onPreviewClicked = function(song, $event){
                    helper.gotoState('song-modal', {
                        id: song.youtubeId,
                        type: 'ytap',
                        name: songService.getSongName(song)
                    });
                    $event.stopPropagation();
                };

                $scope.$on('tutorial:change', function(){
                    $scope.resetYoutubeSearch();
                });

                $scope.returnToSearchResults = function($event){
                    $scope.addingSongDone();

                    $event && $event.stopPropagation();
                };

                $scope.aiSearch = async (question) => {
                    if ($scope.aiSearchLoading) return;

                    try {
                        $scope.aiSearchLoading = true;
                        $scope.aiResults = null;

                        $scope.aiResults = await songService.aiSearch({ question });
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        delete $scope.aiSearchLoading;
                        try { $scope.$digest(); } catch(e) {}
                    }
                };

                $scope.onAiResultClicked = (aiResult) => {
                    $scope.querySearch = `${aiResult.artist} - ${aiResult.title}`;
                    $scope.getYoutubeSuggestions();
                };

                // Make sure the binding shit is clean when shits close..

                $scope.closeWhenDocClicked = function(evt){
                    var localParent = '#add-song-holder';
                    var clickableAreaToClose = '#add-song-holder .content';

                    if($(evt.target).is(localParent) || $(evt.target).parents(clickableAreaToClose).length ||
                        $(evt.target).parents('.song-modal').length ||
                        $(evt.target).parents('.beatsInsufficient-modal').length ||
                        $(evt.target).parents('#youtube-suggestions-results').length) return;

                    $scope.close();
                };

                var offFuncs = [];
                $scope.$on('$destroy', function(){
                    for(var i=0;i<offFuncs.length;i++){
                        if(typeof offFuncs[i] === 'function'){
                            offFuncs[i]();
                        }
                    }
                });

                offFuncs.push($rootScope.$on('document:click', function(ngEvt, evt){
                   // $scope.closeWhenDocClicked(evt);
                }));

                offFuncs.push($rootScope.$on('document:keyup', function(ngEvt, evt){
                    if(evt.keyCode === 27) return $scope.close();
                }));

                $scope.getInputPlaceholder = function () {
                  //  if($scope.inRoomList) return 'Instant music search';
                    return $scope.inWikiSongPage ? 'Search BeatSense Wiki' : 'Search and add your music';
                };

                $scope.digDeeper = function(){

                    if(!helper.isUserLoggedIn()){
                        return helper.gotoState('join', {
                            message: 'Sign up to get full search results'
                        });
                    }

                    var isPayed = false;
                    if(!$scope.youtubeSearchResults.length || $scope.youtubeSearchResults.length > 3){

                        analyticsService.trackActionEvent({
                            eventAction: 'DigDeeper',
                            eventLabel: 'paid',
                            eventValue: $scope.youtubeSearchResults.length
                        });

                        if(!beatsService.userCanBuy(beatsService.keys.digDeeper)){

                            analyticsService.trackActionEvent({
                                eventAction: 'DigDeeper',
                                eventLabel: 'paid_not_enough_beats',
                                eventValue: $scope.youtubeSearchResults.length
                            });

                           return helper.gotoState('beatsInsufficient', {
                               reason: 'DigDeeper'
                           });
                        }
                        isPayed = true;
                    }else{
                        analyticsService.trackActionEvent({
                            eventAction: 'DigDeeper',
                            eventLabel: 'free',
                            eventValue: $scope.youtubeSearchResults.length
                        });
                    }

                    $scope.digDeeperLoading = true;
                    $scope.searchOnYoutube({ searchType: 'yt' }).then(function (res) {
                        $scope.digDeeperLoading = false;
                        if(isPayed && res.data.data.length){
                            analyticsService.trackActionEvent({
                                eventAction: 'DigDeeper',
                                eventLabel: 'paid_purchased',
                                eventValue: $scope.youtubeSearchResults.length
                            });

                            helper.trackBeatsEvent('DigDeeperPurchase', 'DigDeeper');
                            return beatsService.purchaseHasBeenMade(beatsService.keys.digDeeper);
                        }
                    }).catch(function () {
                        $scope.digDeeperLoading = false;
                    });
                };

                $scope.close = function($event){
                    $rootScope.$broadcast('song:search:toggle');
                    if($event) $event.stopPropagation();
                };

                if($scope.querySearch){
                    $scope.onSongSearchKeyup({});
                }

                $(searchResultsSelector).on('ps-scroll-y', () => {
                    _searchResultsScrollPosition = $(searchResultsSelector).scrollTop();
                });

                $scope.$on('song:search', ($evt, { query }) => {
                    if(query !== $scope.querySearch) {
                        $scope.resetYoutubeSearch();
                        $scope.querySearch = query;
                        $scope.getYoutubeSuggestions();
                    }
                });


            },
            link: function($scope, element){

                const inputButton = $(element).find('#txtNewSong');

                if(!$scope.inRoomList && !$scope.inWikiSongPage){
                    $timeout(() => {
                        inputButton.focus();
                    });
                }

                $(inputButton).blur(() => {
                    $scope.hideInputCoverButton = false;
                });

                $scope.hideInputCoverButtonFn = () => {
                    $scope.hideInputCoverButton = true;
                    inputButton.focus();
                };
            }
        };
    });
}(angular));
